
import { PropType, reactive, ref, defineComponent, onMounted, } from "vue";
import { CalendarOutlined, SmileOutlined } from "@ant-design/icons-vue";
import { TrainingSessionNameEnum, TrainingRecordsExaminerDto, AddTrainingRecordsInput } from "@/api-client/client";
import { _Client } from "@/api-client";
import { Form, message } from "ant-design-vue";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import { FormInstance } from 'ant-design-vue/lib/form';

const useForm = Form.useForm;

export default defineComponent({
    props: {
        examinerIdList: {
            type: Array as PropType<string[]>,
            required: true,
        },
        onClose: {
            type: Function,
            required: true,
        },
    },
    components: {
        CalendarOutlined,
        SmileOutlined,
    },
    setup(props) {
        const formRef = ref();
        // 表单模型
        const form = ref({
          trainingDate: undefined,
          trainingSession: null,
          trainerSelect:null,
          trainerInput:null,
          trainer:null,
          trainingStatus:"Completed"
        });

        let validateTrainerSelect = async (_rule: Rule, value: string) => {
          if (value === '' && form.value.trainerSelect=="Other") {
            return Promise.reject('Trainer is required');
          }
          
        };

        // 校验规则
        const rules = {
            trainingDate: [
            { required: true, message: 'Training Date is required', trigger: 'change' },
            ],
            trainingSession: [
            { required: true, message: 'Training Session is required', trigger: 'change' },
            ],
            trainerSelect: [
            { required: true, message: 'Trainer is required', trigger: 'change' },
            ],
            trainerInput: [
            { validator: validateTrainerSelect, message: 'Trainer is required', trigger: 'change' },
            ],
            trainingStatus: [
            { required: true, message: 'Training Status is required', trigger: 'change' },
            ],
        };
       
        const examinerIdList = props.examinerIdList;
        const saveLoading = ref(false);
        const spinning = ref(false);
        const title = ref<string>("Edit Examiner Task");
        const examinerForm = ref<TrainingRecordsExaminerDto[]>([]);
        const selectExaminerForm = ref<TrainingRecordsExaminerDto[]>([]);

        const close = (isRefresh: Boolean) => props.onClose(isRefresh);

        // 处理关闭标签的事件
        const handleClose = (
            tagToRemove: { examinerId?: string | undefined; examinerName?: string | undefined },
            index: number
        ) => {
            // 从数组中移除标签
            examinerForm.value?.splice(index, 1);
        };

        const getExaminerById = () => {
            spinning.value = true;
            _Client.trainingRecordsClient.getExaminerById(examinerIdList).then(rep => {
                examinerForm.value=rep;
            }).finally(() => { spinning.value = false; })
        }

        const getSelectExaminer = () => {
            spinning.value = true;
            _Client.trainingRecordsClient.selectExaminer().then(rep => {
                selectExaminerForm.value=rep;
            }).finally(() => { spinning.value = false; })
        }

        const addTrainingRecords = () => {
            formRef.value?.validate().then(() => {
            spinning.value = true;
            saveLoading.value = true;
            _Client.trainingRecordsClient.addTrainingRecords(new AddTrainingRecordsInput({
                examinerIdList: examinerForm.value.map(x => x.examinerId),
                trainingDate:new Date(form.value.trainingDate!.format("YYYY-MM-DD")),
                trainingSession:form.value.trainingSession!,
                trainer:form.value.trainerSelect=="Other"?form.value.trainerInput!:form.value.trainerSelect!,
                traIningStatus:form.value.trainingStatus,
            })).then((res) => {
                if(res.isSuccessful){
                    message.success("Save Successfully");
                    close(true);
                }
                else{
                    message.error(res.message);
                }
            }).finally(() => {
                spinning.value = false;
                saveLoading.value = false;
            })
        })
        }

        const filterOption = (_input: string, _options: { title: string }) => {
            return _options.title.toLowerCase().indexOf(_input.toLowerCase()) >= 0;
        };

        onMounted(() => {
            getExaminerById();
            getSelectExaminer();
        })

        return {
            close,
            handleClose,
            addTrainingRecords,
            filterOption,
            formRef,
            form,
            rules,
            title,
            dayjs,
            saveLoading,
            spinning,
            examinerForm,
            selectExaminerForm,
            trainingSessionNameEnum:TrainingSessionNameEnum
        };
    },
});
