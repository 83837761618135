
import { ref, defineComponent, onMounted, onActivated, createVNode } from "vue";
import { _Client } from "@/api-client";
import {
  ChangeExaminerAvailabilityStatusEnum,
  WorkStatusEnum,
  PageResultOfListOfChangeExaminerAvailabilityDto,
  ChangeExaminerAvailabilityInput,
  ConfirmChangeExaminerAvailabilityModel,
  ChangeExaminerAvailabilityDto,
  DeclineReasonModel
} from "@/api-client/client";
import { Modal, message ,Form} from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import Pagination from "@/components/Pagination/index.vue";
import dayjs, { Dayjs } from "dayjs";
import Log from './log.vue'
type RangeValue = [Dayjs, Dayjs];

const today = new Date();
const currentYear = today.getFullYear();

const columns = [
  {
    title: "Examiner No.",
    dataIndex: "examinerNo",
  },
  {
    title: "Examiner Name",
    dataIndex: "examinerName",
  },
  {
    title: "Work Type",
    dataIndex: "workType",
  },
  {
    title: "AM Task",
    dataIndex: "amTask",
  },
  {
    title: "PM Task",
    dataIndex: "pmTask",
  },
  {
    title: "Request Time",
    dataIndex: "requestTime",
  },
  {
    title: "Examining Date",
    dataIndex: "examiningDate",
  },
  {
    title: "Availability",
    dataIndex: "changedAvailability",
  },
  {
    title: "Reason",
    dataIndex: "reason",
  },
  {
    title: "Status",
    dataIndex: "status",
  },
  {
    title: "Action",
    dataIndex: "action",
    fixed: "right",
    width: 200,
  },
];

const useForm = Form.useForm;
export default defineComponent({
  components: {
    Pagination,
    ExclamationCircleOutlined,
    Log
  },
  setup() {
    const confirmLoading = ref(false);
    const decineLoading = ref(false);
    const confirmParames = ref<ConfirmChangeExaminerAvailabilityModel>(new ConfirmChangeExaminerAvailabilityModel({ changeExaminerAvailabilityId: "" }));
    const workStatusEnum = WorkStatusEnum;
    const availabilityStatusEnum = ChangeExaminerAvailabilityStatusEnum;
    const showConfirm = ref<boolean>(false);
    const showErrorMessage = ref<boolean>(false);
    const errorMessage = ref<String | undefined>("");
    const showLogModal = ref<boolean>(false)
    const changeExaminerId = ref<string>("");
    const decineShow = ref(false);
    const formData = ref<string | null>(null);
    const declineReasonModelParames = ref<DeclineReasonModel>(new DeclineReasonModel({ id: "", reason:""}));
     // 校验规则
    const rules = {
      reason: [
        { required: true, message: 'This field is required!', trigger: 'blur' },
        {
          pattern: /^[a-zA-Z0-9 \n,./'-]+$/, 
          message: 'There are characters that are not allowed to be entered.',
          trigger: 'blur',
        },
      ],
    };

    const {resetFields, validate, validateInfos } = useForm(declineReasonModelParames, rules);
    const changeExaminingDate = ref<Number | null>(null);
    const isBatchConfirm = ref<boolean | undefined>(undefined);
    const hackValue = ref<RangeValue>();
    const dates = ref<RangeValue>();
    const value = ref<RangeValue>();
    const onOpenChange = (open: boolean) => {
      if (open) {
        dates.value = [] as any;
        hackValue.value = [] as any;
      } else {
        hackValue.value = undefined;
      }
    };

    const onCalendarChange = (val: RangeValue) => {
      dates.value = val;
    };

    const onChange = (val: RangeValue) => {
      value.value = val;
    };

    const disabledDate = (current: Dayjs) => {
      if (!dates.value || (dates.value as any).length === 0) {
        return false;
      }
      const tooLate = dates.value[0] && current.diff(dates.value[0], 'days') > 365;
      const tooEarly = dates.value[1] && dates.value[1].diff(current, 'days') > 365;
      return tooEarly || tooLate;
    };

    const tableLoading = ref<boolean>(false);
    const modifyId = ref("");
    const selectedRowKeys = ref<ChangeExaminerAvailabilityDto[]>([]); //// checkbox 已选中的数据

    const searchParames = ref<ChangeExaminerAvailabilityInput>(
      new ChangeExaminerAvailabilityInput(<ChangeExaminerAvailabilityInput>{ pageIndex: 1, pageSize: 10 })
    );

    const tableSource = ref(new PageResultOfListOfChangeExaminerAvailabilityDto());

    const getList = () => {
      tableLoading.value = true;
      if (dates!.value == undefined) {
        message.error("Please select Examining Date.");
        tableLoading.value = false;
        return false;
      }

        searchParames.value.examiningDateStart=new Date(dates!.value![0].format("YYYY-MM-DD"))
        searchParames.value.examiningDateEnd=new Date(dates!.value![1].format("YYYY-MM-DD"))

      _Client.changeExaminerAvailabilityClient
        .search(searchParames.value!)
        .then((rep) => {
          tableSource.value = rep;
        })
        .finally(() => (tableLoading.value = false));
    };

    const resetSearchParams = () => {
      searchParames.value = new ChangeExaminerAvailabilityInput(<ChangeExaminerAvailabilityInput>{ pageIndex: 1, pageSize: 10 })
      getLockDateMax();
    };

    const getLockDateMax = () => {
      tableLoading.value = true;
      _Client.changeExaminerAvailabilityClient
        .getLockDateMax()
        .then((rep) => {
           dates.value = [dayjs().add(-60, "day"), dayjs(rep)];
           value.value = [dayjs().add(-60, "day"), dayjs(rep)];
          getList();
        })
        .finally(() => (tableLoading.value = false));
    };


    const onSelectChange = (keys: any) => {

      selectedRowKeys.value = keys;
    };

    const getCheckboxProps = (record: any) => {
      return {
        disabled: record.canBeSelected == false, // Disable checkbox when status is 1
      };
    };

    const showConfirmModel = ((changeExaminerAvailabilityId: string) => {
      showConfirm.value = true;
      isBatchConfirm.value = false;
      confirmParames.value.changeExaminerAvailabilityId = changeExaminerAvailabilityId;
    })

    const showLogModel = ((record: any) => {
       
      changeExaminerId.value = record.examinerId;
      changeExaminingDate.value = record.dateUnixTimeSeconds;
      showLogModal.value= true;
    })

    const decineClose = (() => {
      resetFields();
      decineShow.value= false;
     })

    
    const showdecineModel = ((record: any) => {
      declineReasonModelParames.value.reason= "";
      declineReasonModelParames.value.id = record.changeExaminerAvailabilityId;
      decineShow.value= true;
     })

    const batchshowConfirmModel = (() => {
      showConfirm.value = true;
    })
    const close = (() => {
      showConfirm.value = false;
      isBatchConfirm.value = true;
    })

    const closeError = (() => {
      showErrorMessage.value = false;
    })

    //close
    const closeLog = async (refresh: boolean = false) => {
      showLogModal.value = false
    }

    const confirm = (() => {
      showConfirm.value = false;
      confirmLoading.value = true;
      if (isBatchConfirm!.value == false) {
        singleConfirm();
      }
      else {
        batchConfirm();
      }

      isBatchConfirm.value = undefined;
    })

    // 单个Confirm
    const singleConfirm = (() => {
      _Client.changeExaminerAvailabilityClient
        .confirm(confirmParames.value)
        .then((rep) => {
          if (!rep.isSuccessful) {
            errorMessage.value = rep.message;
            showErrorMessage.value = true;
          }
          else {
            getList();
          }

          confirmLoading.value = false;

        })
        .finally(() => (showConfirm.value = false));
    })

    // 批量 Confirm
    const batchConfirm = (() => {
      const selectedIds = selectedRowKeys.value.map(row => row.changeExaminerAvailabilityId);
      _Client.changeExaminerAvailabilityClient
        .batchConfirm(selectedIds)
        .then((rep) => {
          if (!rep.isSuccessful) {
            errorMessage.value = rep.message;
            showErrorMessage.value = true;
          }
          else {
            getList();
          }

          confirmLoading.value = false;

        })
        .finally(() => (showConfirm.value = false));
    })

     // 表单提交
     const onSubmit = () => {
      validate().then(async () => {
        decineLoading.value =true;
        _Client.changeExaminerAvailabilityClient
        .declineReason(declineReasonModelParames.value)
        .then((rep) => {
          decineLoading.value =false;
          getList();
        })
        .finally(() => (decineShow.value = false));
            }).catch(error => {});
    };

    onActivated(() => {
      getLockDateMax();
    });

    return {
      tableLoading,
      columns,
      tableSource,
      searchParames,
      currentYear,
      modifyId,
      hackValue,
      value,
      workStatusEnum,
      availabilityStatusEnum,
      dayjs,
      ChangeExaminerAvailabilityStatusEnum,
      showConfirm,
      selectedRowKeys,
      confirmLoading,
      showErrorMessage,
      errorMessage,
      changeExaminerId,
      showLogModal,
      changeExaminingDate,
      decineShow,
      declineReasonModelParames,
      formData,
      rules,
      validateInfos,
      decineLoading,
      onChange,
      onCalendarChange,
      onOpenChange,
      resetSearchParams,
      getList,
      close,
      disabledDate,
      onSelectChange,
      getCheckboxProps,
      showConfirmModel,
      confirm,
      batchshowConfirmModel,
      closeError,
      showLogModel,
      showdecineModel,
      closeLog,
      decineClose,
      onSubmit
    };
  },
});
