
import { ExclamationCircleFilled } from "@ant-design/icons-vue";
import { ref, onMounted, inject, watch, onActivated, defineComponent, reactive } from "vue";
import { _Client } from "@/api-client";
import { CreateExaminerAllocationInput, AutoDefaultSettingDto, TimeWindowEnum } from "@/api-client/client";
import { message } from "ant-design-vue";
export default defineComponent({
    components: {
        ExclamationCircleFilled,
    },
    props: {
        close: {
            type: Function,
            required: true
        }
    },
    setup(props) {

        const planId = inject('examinerAllocationPlanId', '')
        const loading = ref(false)
        const allocateLoading = ref(false)
        const autoDefaultSetting = ref<AutoDefaultSettingDto[]>([]);
        const createExaminerAllocationInput = reactive<CreateExaminerAllocationInput>(new CreateExaminerAllocationInput());

        const close = () => {
            props.close()
        }

        const autoAllocation = () => {
            allocateLoading.value = true;
            createExaminerAllocationInput.planId = planId;
            _Client.examDayPlanClient.createExaminerAllocationTask(createExaminerAllocationInput).then(rep => {
                message.success("success");
                close();
            }).finally(() => allocateLoading.value = false)
        }

        const getAutoDefaultSetting = (timeWindow: TimeWindowEnum): AutoDefaultSettingDto => {
            return autoDefaultSetting.value!.find(item => item.timeWindow == timeWindow) || new AutoDefaultSettingDto();
        }

        onMounted(() => {
            loading.value = true
            _Client.examDayPlanClient.getAutoAllocationSeting(planId).then(rep => {
                autoDefaultSetting.value = rep;
                createExaminerAllocationInput.init({
                    spkamNumber: getAutoDefaultSetting(TimeWindowEnum.AM).customExaminerRequired?.icsNumber,
                    spkpmNumber: getAutoDefaultSetting(TimeWindowEnum.PM).customExaminerRequired?.icsNumber,
                    eoramNumber: getAutoDefaultSetting(TimeWindowEnum.AM).customExaminerRequired?.eorNumber,
                    eorpmNumber: getAutoDefaultSetting(TimeWindowEnum.PM).customExaminerRequired?.eorNumber,
                    jpamNumber: getAutoDefaultSetting(TimeWindowEnum.AM).customExaminerRequired?.jpNumber,
                    jppmNumber: getAutoDefaultSetting(TimeWindowEnum.PM).customExaminerRequired?.jpNumber,
                    osmamNumber: 0,
                    osmpmNumber: 0
                });
            }).finally(() => {
                loading.value = false
            })
        })

        return {
            close,
            getAutoDefaultSetting,
            autoAllocation,
            loading,
            allocateLoading,
            timeWindowEnum: TimeWindowEnum,
            createExaminerAllocationInput,
        }
    }
})
