
import {
  ref,
  defineComponent,
  onMounted,
  onActivated,
  createVNode,
  UnwrapRef,
} from "vue";
import { _Client } from "@/api-client";
import { AllocationSettingsInput, AllocationSettingsDto, EnumTaskUsageRule, AllocationSettingDefaultTimelineInput, AllocationDeploymentRateInput, AllocationDefaultallocationRuleInput, AllocationExamTypeSequceDto, EnumTaskExamType, EnumTaskSequence, AllocationTaskRadioSettingsDto, EnumTaskTimeWindow } from "@/api-client/client";
import add from '@/views/availabilityManagement/noneExaminingDate/add.vue'
import modify from '@/views/availabilityManagement/noneExaminingDate/modify.vue'
import { Modal, SelectProps, message } from "ant-design-vue";
import { EditOutlined, QuestionCircleOutlined, InfoCircleOutlined } from "@ant-design/icons-vue";
import Pagination from '@/components/Pagination/index.vue'
import { reactive, computed } from 'vue';
import { init, number } from "echarts";
const today = new Date();
const currentYear = today.getFullYear();
export default defineComponent({
  components: {
    add,
    modify,
    Pagination,
    InfoCircleOutlined,
    EditOutlined
  },
  setup() {
    const tableLoading = ref<boolean>(false)

    const editableData: UnwrapRef<Record<string, DataItem>> = reactive({});
    const tableDataSource: UnwrapRef<Record<string, DataItem>> = reactive({});
    interface TableColumn {
      title: string;
      dataIndex: string;
      key: string;
      children: TableColumn[];
    }
    enum EnumEditRule {
      Taskratio,
      Defaultallocation,
      DeploymentRate,
      DefaultTimeline
    }
    const editingTaskratio = ref(false)
    const editingDefaultallocationRule = ref(false)
    const editingDeploymentRate = ref(false)
    const editingDefaultTimeline = ref(false)
    const saveLoading = ref(false)
    const flag = ref(false)
    const initData = ref([
      {
        "TimeSolt": "AM1",
        "IOC-SPK-Max": 0,
        "IOC-SPK-Standard": 0,
        "IOC-SPK-Min": 0,
        "IOP-SPK-Max": 0,
        "IOP-SPK-Standard": 0,
        "IOP-SPK-Min": 0,
        "JP-Max": 0,
        "JP-Standard": 0,
        "JP-Min": 0,
        "EOR-Max": 0,
        "EOR-Standard": 0,
        "EOR-Min": 0,
        "OSM-Max": 0,
        "OSM-Standard": 0,
        "OSM-Min": 0
      },
      {
        "TimeSolt": "AM2",
        "IOC-SPK-Max": 0,
        "IOC-SPK-Standard": 0,
        "IOC-SPK-Min": 0,
        "IOP-SPK-Max": 0,
        "IOP-SPK-Standard": 0,
        "IOP-SPK-Min": 0,
        "JP-Max": 0,
        "JP-Standard": 0,
        "JP-Min": 0,
        "EOR-Max": 0,
        "EOR-Standard": 0,
        "EOR-Min": 0,
        "OSM-Max": 0,
        "OSM-Standard": 0,
        "OSM-Min": 0
      },
      {
        "TimeSolt": "PM1",
        "IOC-SPK-Max": 0,
        "IOC-SPK-Standard": 0,
        "IOC-SPK-Min": 0,
        "IOP-SPK-Max": 0,
        "IOP-SPK-Standard": 0,
        "IOP-SPK-Min": 0,
        "JP-Max": 0,
        "JP-Standard": 0,
        "JP-Min": 0,
        "EOR-Max": 0,
        "EOR-Standard": 0,
        "EOR-Min": 0,
        "OSM-Max": 0,
        "OSM-Standard": 0,
        "OSM-Min": 0
      },
      {
        "TimeSolt": "PM2",
        "IOC-SPK-Max": 0,
        "IOC-SPK-Standard": 0,
        "IOC-SPK-Min": 0,
        "IOP-SPK-Max": 0,
        "IOP-SPK-Standard": 0,
        "IOP-SPK-Min": 0,
        "JP-Max": 0,
        "JP-Standard": 0,
        "JP-Min": 0,
        "EOR-Max": 0,
        "EOR-Standard": 0,
        "EOR-Min": 0,
        "OSM-Max": 0,
        "OSM-Standard": 0,
        "OSM-Min": 0
      }
    ])
    interface DataItem {
      "TimeSolt": string,
      "IOC-SPK-Max": number,
      "IOC-SPK-Standard": number,
      "IOC-SPK-Min": number,
      "IOP-SPK-Max": number,
      "IOP-SPK-Standard": number,
      "IOP-SPK-Min": number,
      "JP-Max": number,
      "JP-Standard": number,
      "JP-Min": number,
      "EOR-Max": number,
      "EOR-Standard": number,
      "EOR-Min": number,
      "OSM-Max": number,
      "OSM-Standard": number,
      "OSM-Min": number
    }

    interface Selections {
      Sequence1: string;
      Sequence2: string;
      Sequence3: string;
      Sequence4: string;
      Sequence5: string;
    }
    const selectOptions = reactive<Selections>({
      Sequence1: '',
      Sequence2: '',
      Sequence3: '',
      Sequence4: '',
      Sequence5: ''
    })

    const selectedSequence = reactive({
      Sequence1: 'IOC-SPK',
      Sequence2: 'IOP-SPK',
      Sequence3: 'JP',
      Sequence4: 'EOR',
      Sequence5: 'OSM'
    });

    const selectedColumn = reactive({
      Sequence1: 'IOC-SPK',
      Sequence2: 'IOP-SPK',
      Sequence3: 'JP',
      Sequence4: 'EOR',
      Sequence5: 'OSM'
    });

    const startEdit = (editTpe: EnumEditRule) => {
      switch (editTpe) {
        case EnumEditRule.Taskratio:
          editingTaskratio.value = true;
          break;
        case EnumEditRule.Defaultallocation:
          editingDefaultallocationRule.value = true;
          break;
        case EnumEditRule.DeploymentRate:
          editingDeploymentRate.value = true;
          break;
        case EnumEditRule.DefaultTimeline:
          editingDefaultTimeline.value = true;
          break;
      }
    }
    const cancelEdit = (editTpe: EnumEditRule) => {
      switch (editTpe) {
        case EnumEditRule.Taskratio:
          editingTaskratio.value = false;
          break;
        case EnumEditRule.Defaultallocation:
          editingDefaultallocationRule.value = false;
          break;
        case EnumEditRule.DeploymentRate:
          editingDeploymentRate.value = false;
          break;
        case EnumEditRule.DefaultTimeline:
          editingDefaultTimeline.value = false;
          break;
      }
      getList()
    }
    const saveEdit = (editTpe: EnumEditRule) => {

      let defaultTimelineInput = new AllocationSettingDefaultTimelineInput();
      let deploymentRateInput = new AllocationDeploymentRateInput();
      let allocationSettingsInput = new AllocationSettingsInput();
      let defaultallocationRuleInput = new AllocationDefaultallocationRuleInput();
      let allocationTaskRadioSettings: AllocationTaskRadioSettingsDto[] = []
      let allocationExamTypeSequce: AllocationExamTypeSequceDto[] = []
      saveLoading.value = true
      switch (editTpe) {
        case EnumEditRule.Taskratio:
          let sequenceFlag = false
          flag.value = false
          Object.keys(selectedSequence).forEach(key => {
            var info = new AllocationExamTypeSequceDto()
            switch (key) {
              case "Sequence1":
                info.seq = EnumTaskSequence.Sequence1;
                break;
              case "Sequence2":
                info.seq = EnumTaskSequence.Sequence2;
                break;
              case "Sequence3":
                info.seq = EnumTaskSequence.Sequence3;
                break;
              case "Sequence4":
                info.seq = EnumTaskSequence.Sequence4;
                break;
              case "Sequence5":
                info.seq = EnumTaskSequence.Sequence5;
                break;
              default:

            }
            switch (selectedSequence[key]) {
              case "IOC-SPK":
                info.examType = EnumTaskExamType.IOCSPK;
                break;
              case "IOP-SPK":
                info.examType = EnumTaskExamType.IOPSPK;
                break;
              case "JP":
                info.examType = EnumTaskExamType.JP;
                break;
              case "EOR":
                info.examType = EnumTaskExamType.EOR;
                break;
              case "OSM":
                info.examType = EnumTaskExamType.OSM;
                break;
              default:
                sequenceFlag = true
            }

            allocationExamTypeSequce.push(info)
          })

          var taskTimeWindow = EnumTaskTimeWindow
          const inputs = document.getElementsByName("input-radio");

          inputs.forEach(element => {
            if (element.value == '0' || element.value == '') {
              element.classList.add("input-radio-error")
              flag.value = true
            }
          });

          Object.entries(EnumTaskTimeWindow).map(([name, value]) => {
            Object.keys(selectedSequence).forEach(key => {
              let taskRadioInfo = new AllocationTaskRadioSettingsDto()
              taskRadioInfo.taskUsageRule = form.value.TaskUsageRule

              switch (selectedSequence[key as keyof Selections]) {
                case "IOC-SPK":
                  taskRadioInfo.examType = EnumTaskExamType.IOCSPK;
                  taskRadioInfo.timeWindows = value
                  taskRadioInfo.maxNumber = editableData[name]["IOC-SPK-Max"]
                  taskRadioInfo.minNumber = editableData[name]["IOC-SPK-Min"]
                  taskRadioInfo.standandNumber = editableData[name]["IOC-SPK-Standard"]
                  break;
                case "IOP-SPK":
                  taskRadioInfo.examType = EnumTaskExamType.IOPSPK;
                  taskRadioInfo.timeWindows = value
                  taskRadioInfo.maxNumber = editableData[name]["IOP-SPK-Max"]
                  taskRadioInfo.minNumber = editableData[name]["IOP-SPK-Min"]
                  taskRadioInfo.standandNumber = editableData[name]["IOP-SPK-Standard"]
                  break;
                case "JP":
                  taskRadioInfo.examType = EnumTaskExamType.JP;
                  taskRadioInfo.timeWindows = value
                  taskRadioInfo.maxNumber = editableData[name]["JP-Max"]
                  taskRadioInfo.minNumber = editableData[name]["JP-Min"]
                  taskRadioInfo.standandNumber = editableData[name]["JP-Standard"]
                  break;
                case "EOR":
                  taskRadioInfo.examType = EnumTaskExamType.EOR;
                  taskRadioInfo.timeWindows = value
                  taskRadioInfo.maxNumber = editableData[name]["EOR-Max"]
                  taskRadioInfo.minNumber = editableData[name]["EOR-Min"]
                  taskRadioInfo.standandNumber = editableData[name]["EOR-Standard"]
                  break;
                case "OSM":
                  taskRadioInfo.examType = EnumTaskExamType.OSM;
                  taskRadioInfo.timeWindows = value
                  taskRadioInfo.maxNumber = editableData[name]["OSM-Max"]
                  taskRadioInfo.minNumber = editableData[name]["OSM-Min"]
                  taskRadioInfo.standandNumber = editableData[name]["OSM-Standard"]
                  break;
              }
              allocationTaskRadioSettings.push(taskRadioInfo)
            })
          });

          if (flag.value == true) {
            message.destroy()
            message.error("Please enter a positive integer.");
            saveLoading.value = false
            return
          }
          if (sequenceFlag) {
            message.error("Default ratio adjustment sequence can not be empty");
            saveLoading.value = false
            return
          }
          allocationSettingsInput.taskUsageRule = form.value.TaskUsageRule
          allocationSettingsInput.allocationTaskRadioSettings = allocationTaskRadioSettings
          allocationSettingsInput.allocationExamTypeSequces = allocationExamTypeSequce
          _Client.allocationSettingsClient.saveTaskratio(allocationSettingsInput)
          editingTaskratio.value = false;
          break;
        case EnumEditRule.Defaultallocation:
          defaultallocationRuleInput.taskUsageRule = form.value.TaskUsageRule
          defaultallocationRuleInput.backupExaminerRate = form.value.BackupExaminerRate
          defaultallocationRuleInput.isBackUpExaminersRule = Number(form.value.IsBackUpExaminersRule)
          _Client.allocationSettingsClient.saveDefaultAllocation(defaultallocationRuleInput).then(() => {
            getList();
          });
          editingDefaultallocationRule.value = false;
          break;
        case EnumEditRule.DeploymentRate:
          deploymentRateInput.taskUsageRule = form.value.TaskUsageRule
          deploymentRateInput.spkDeploymentRate = form.value.SpkDeploymentRate
          deploymentRateInput.osmDeploymentRate = form.value.OsmDeploymentRate
          deploymentRateInput.olmDeploymentRate = form.value.OlmDeploymentRate
          _Client.allocationSettingsClient.saveDeploymentRate(deploymentRateInput).then(() => {
            getList();
          });
          editingDeploymentRate.value = false;
          break;
        case EnumEditRule.DefaultTimeline:
          defaultTimelineInput.taskUsageRule = form.value.TaskUsageRule
          defaultTimelineInput.beforeNoticExaminerDays = form.value.BeforeNoticExaminerDays
          _Client.allocationSettingsClient.saveDefaultTimeline(defaultTimelineInput).then(() => {
            getList();
          });
          editingDefaultTimeline.value = false;
          break;
      }
      saveLoading.value = false

    }

    const sequenceOptions = ref<SelectProps['options']>([
      { value: 'IOC-SPK', label: 'IOC-SPK' },
      { value: 'IOP-SPK', label: 'IOP-SPK' },
      { value: 'JP', label: 'JP' },
      { value: 'EOR', label: 'EOR' },
      { value: 'OSM', label: 'OSM' },
    ]);

    const columns = computed((): TableColumn[] => [
      {
        title: "",
        dataIndex: "TimeSolt",
        key: "TimeSolt",
        children: []
      },
      ...Object.entries(selectedColumn).map(([sequence, selection]): TableColumn => ({
        title: selection,
        dataIndex: sequence,
        key: sequence,
        children: [
          {
            title: 'Max',
            dataIndex: selection + '-Max',
            key: selection + '-Max',
            children: []
          },
          {
            title: 'Standard',
            dataIndex: selection + '-Standard',
            key: selection + '-Standard',
            children: []
          },
          {
            title: 'Min',
            dataIndex: selection + '-Min',
            key: selection + '-Min',
            children: []
          },
        ]
      })
      )]
    );
    const form = ref({
      TaskUsageRule: EnumTaskUsageRule.HighMonth,
      SpkDeploymentRate: 0,
      OlmDeploymentRate: 0,
      OsmDeploymentRate: 0,
      IsBackUpExaminersRule: "1",
      BackupExaminerRate: 0,
      BeforeNoticExaminerDays: 0,
    }
    )

    const handlerInputRadioChange = (e: Event) => {
      const input = e.target as HTMLInputElement;
      const timeSolt = input.getAttribute('data-timesolt');
      const dataIndex = input.getAttribute('data-dataindex');
      input.value = input.value.replace(/\D/g, '')
      if (input.value == '' || input.value == '0') {
        input.classList.add("input-radio-error")
        message.destroy()
        message.error('Please enter a positive integer.')
      }
      else {
        input.classList.remove('input-radio-error');
      }
      editableData[timeSolt][dataIndex] = Number(input.value)
    }

    const handlerFormIndexChange = (e: Event) => {
      const input = e.target as HTMLInputElement;
      const key = input.getAttribute('data-customer');
      const dataMaxValue = input.getAttribute('data-max');
      input.value = input.value.replace(/\D/g, '')
      if (input.value == '') {
        input.value = '0'
      }
      if (dataMaxValue == undefined || Number(input.value) <= Number(dataMaxValue)) {
        form.value[key] = Number(input.value)
      } else {
        form.value[key] = input.value = dataMaxValue
      }
    }

    const handleChange = (sequence: string, value: string) => {

      Object.keys(selectedSequence).forEach(key => {
        if (selectedSequence[sequence] == selectedSequence[key] && sequence != key) {
          selectedSequence[key] = ''; // 重置重复选择
        }
      });

      selectedSequence[sequence] = value
    }

    onMounted(() => {
      getList();
    })

    const switchTaskRule = (() => {
      saveLoading.value = true
      _Client.allocationSettingsClient.search(form.value.TaskUsageRule).then(rep => {
        if (rep.allocationDefaultSettings != undefined) {
          // form.value.TaskUsageRule = rep.allocationDefaultSettings.taskUsageRule
          form.value.BackupExaminerRate = rep.allocationDefaultSettings.backupExaminerRate
          form.value.IsBackUpExaminersRule = rep.allocationDefaultSettings.isBackUpExaminersRule ? "1" : "0"
          form.value.SpkDeploymentRate = rep.allocationDefaultSettings.spkDeploymentRate
          form.value.OsmDeploymentRate = rep.allocationDefaultSettings.osmDeploymentRate
          form.value.OlmDeploymentRate = rep.allocationDefaultSettings.olmDeploymentRate
          form.value.BeforeNoticExaminerDays = rep.allocationDefaultSettings.beforeNoticExaminerDays
        }
        if (rep.allocationExamTypeSequces != undefined) {
          Object.keys(selectedSequence).forEach(keySeq => {
            Object.entries(rep.allocationExamTypeSequces).forEach(([key, value]) => {
              if (value.seq.toString() == keySeq) {
                switch (value.examType) {
                  case EnumTaskExamType.IOCSPK:
                    selectedSequence[keySeq as keyof Selections] = 'IOC-SPK'
                    break;
                  case EnumTaskExamType.IOPSPK:
                    selectedSequence[keySeq as keyof Selections] = 'IOP-SPK'
                    break;
                  case EnumTaskExamType.JP:
                    selectedSequence[keySeq as keyof Selections] = 'JP'
                    break;
                  case EnumTaskExamType.EOR:
                    selectedSequence[keySeq as keyof Selections] = 'EOR'
                    break;
                  case EnumTaskExamType.OSM:
                    selectedSequence[keySeq as keyof Selections] = 'OSM'
                    break;
                }
              }
            });
          })

        }

        if (rep.allocationTaskRadioSettings != undefined) {

          Object.entries(EnumTaskTimeWindow).forEach(([tkey, tvalue]) => {
            Object.entries(rep.allocationTaskRadioSettings).forEach(([key, value]) => {
              let i = 0;
              switch (value.timeWindows) {
                case EnumTaskTimeWindow.AM1:
                  i = 0;
                  break;
                case EnumTaskTimeWindow.AM2:
                  i = 1;
                  break;
                case EnumTaskTimeWindow.PM1:
                  i = 2;
                  break;
                case EnumTaskTimeWindow.PM2:
                  i = 3;
                  break;
              }
              if (value.examType == EnumTaskExamType.IOCSPK) {
                initData.value[i]["IOC-SPK-Max"] = value.maxNumber
                initData.value[i]["IOC-SPK-Min"] = value.minNumber
                initData.value[i]["IOC-SPK-Standard"] = value.standandNumber
              }
              if (value.examType == EnumTaskExamType.IOPSPK) {
                initData.value[i]["IOP-SPK-Max"] = value.maxNumber
                initData.value[i]["IOP-SPK-Min"] = value.minNumber
                initData.value[i]["IOP-SPK-Standard"] = value.standandNumber
              }
              if (value.examType == EnumTaskExamType.JP) {
                initData.value[i]["JP-Max"] = value.maxNumber
                initData.value[i]["JP-Min"] = value.minNumber
                initData.value[i]["JP-Standard"] = value.standandNumber
              }
              if (value.examType == EnumTaskExamType.EOR) {
                initData.value[i]["EOR-Max"] = value.maxNumber
                initData.value[i]["EOR-Min"] = value.minNumber
                initData.value[i]["EOR-Standard"] = value.standandNumber
              }
              if (value.examType == EnumTaskExamType.OSM) {
                initData.value[i]["OSM-Max"] = value.maxNumber
                initData.value[i]["OSM-Min"] = value.minNumber
                initData.value[i]["OSM-Standard"] = value.standandNumber
              }
            })
          })
          initData.value.forEach(item => {
            editableData[item.TimeSolt] = item;
          });
        }
        saveLoading.value = false
      }).finally()
    })
    const getList = (() => {
      saveLoading.value = true
      _Client.allocationSettingsClient.searchALL().then(rep => {
        if (rep.allocationDefaultSettings != undefined) {
          form.value.TaskUsageRule = rep.allocationDefaultSettings.taskUsageRule
          form.value.BackupExaminerRate = rep.allocationDefaultSettings.backupExaminerRate
          form.value.IsBackUpExaminersRule = rep.allocationDefaultSettings.isBackUpExaminersRule ? "1" : "0"
          form.value.SpkDeploymentRate = rep.allocationDefaultSettings.spkDeploymentRate
          form.value.OsmDeploymentRate = rep.allocationDefaultSettings.osmDeploymentRate
          form.value.OlmDeploymentRate = rep.allocationDefaultSettings.olmDeploymentRate
          form.value.BeforeNoticExaminerDays = rep.allocationDefaultSettings.beforeNoticExaminerDays
        }
        if (rep.allocationExamTypeSequces != undefined) {
          Object.keys(selectedSequence).forEach(keySeq => {
            Object.entries(rep.allocationExamTypeSequces).forEach(([key, value]) => {
              if (value.seq.toString() == keySeq) {
                switch (value.examType) {
                  case EnumTaskExamType.IOCSPK:
                    selectedSequence[keySeq as keyof Selections] = 'IOC-SPK'
                    break;
                  case EnumTaskExamType.IOPSPK:
                    selectedSequence[keySeq as keyof Selections] = 'IOP-SPK'
                    break;
                  case EnumTaskExamType.JP:
                    selectedSequence[keySeq as keyof Selections] = 'JP'
                    break;
                  case EnumTaskExamType.EOR:
                    selectedSequence[keySeq as keyof Selections] = 'EOR'
                    break;
                  case EnumTaskExamType.OSM:
                    selectedSequence[keySeq as keyof Selections] = 'OSM'
                    break;
                }
              }
            });
          })

        }

        if (rep.allocationTaskRadioSettings != undefined) {

          Object.entries(EnumTaskTimeWindow).forEach(([tkey, tvalue]) => {
            Object.entries(rep.allocationTaskRadioSettings).forEach(([key, value]) => {
              let i = 0;
              switch (value.timeWindows) {
                case EnumTaskTimeWindow.AM1:
                  i = 0;
                  break;
                case EnumTaskTimeWindow.AM2:
                  i = 1;
                  break;
                case EnumTaskTimeWindow.PM1:
                  i = 2;
                  break;
                case EnumTaskTimeWindow.PM2:
                  i = 3;
                  break;
              }
              if (value.examType == EnumTaskExamType.IOCSPK) {
                initData.value[i]["IOC-SPK-Max"] = value.maxNumber
                initData.value[i]["IOC-SPK-Min"] = value.minNumber
                initData.value[i]["IOC-SPK-Standard"] = value.standandNumber
              }
              if (value.examType == EnumTaskExamType.IOPSPK) {
                initData.value[i]["IOP-SPK-Max"] = value.maxNumber
                initData.value[i]["IOP-SPK-Min"] = value.minNumber
                initData.value[i]["IOP-SPK-Standard"] = value.standandNumber
              }
              if (value.examType == EnumTaskExamType.JP) {
                initData.value[i]["JP-Max"] = value.maxNumber
                initData.value[i]["JP-Min"] = value.minNumber
                initData.value[i]["JP-Standard"] = value.standandNumber
              }
              if (value.examType == EnumTaskExamType.EOR) {
                initData.value[i]["EOR-Max"] = value.maxNumber
                initData.value[i]["EOR-Min"] = value.minNumber
                initData.value[i]["EOR-Standard"] = value.standandNumber
              }
              if (value.examType == EnumTaskExamType.OSM) {
                initData.value[i]["OSM-Max"] = value.maxNumber
                initData.value[i]["OSM-Min"] = value.minNumber
                initData.value[i]["OSM-Standard"] = value.standandNumber
              }
            })
          })
        }
        initData.value.forEach(item => {
          editableData[item.TimeSolt] = item;
        });
        saveLoading.value = false
      }).finally()
    })

    return {
      tableLoading,
      currentYear,
      form,
      close,
      selectOptions,
      selectedColumn,
      selectedSequence,
      handleChange,
      columns,
      sequenceOptions,
      editingTaskratio,
      editingDefaultallocationRule,
      editingDeploymentRate,
      editingDefaultTimeline,
      EnumEditRule,
      startEdit,
      cancelEdit,
      saveEdit,
      editableData,
      initData,
      saveLoading,
      handlerInputRadioChange,
      handlerFormIndexChange,
      getList,
      switchTaskRule
    };
  }
});

