
import { _Client } from '@/api-client';
import { message } from 'ant-design-vue';
import { LeftOutlined } from '@ant-design/icons-vue';
import { useRouter } from 'vue-router';
import { DefaultAllocationRuleDto } from '@/api-client/client';
import { defineComponent, inject, reactive, ref, watch } from "vue";
import kpiDetails from './kpiDetails.vue';
export default defineComponent({
  components: {
    LeftOutlined,
    kpiDetails
  },
  props: {
    score: {
      type: Number || String,
      required: true
    },
    defaultAllocationRule: {
      type: DefaultAllocationRuleDto,
      required: true,
    }
  },
  setup(props) {
    const planId = inject('examinerAllocationPlanId', '')
    const showKpiDetailsModal = ref(false)
    const parames = reactive<DefaultAllocationRuleDto>(DefaultAllocationRuleDto.fromJS({
      allocationRuleName: "",
      allocationRuleAbbreviationName: "",
      backupRate: "0",
      backupExaminerCount: 0
    }));

    const kpiScore = ref<number | string>('-')

    watch(() => props.score, (val) => {
      kpiScore.value = val
    })

    watch(() => props.defaultAllocationRule, (val) => {
      parames.init(val);
    }, { deep: true })

    const kpiDetailsClose = (score: number) => {
      showKpiDetailsModal.value = false
      kpiScore.value = score
    }

    return {
      showKpiDetailsModal,
      parames,
      kpiScore,
      kpiDetailsClose
    }
  }
})
