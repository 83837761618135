
import dayjs, { Dayjs } from 'dayjs';
import { _Client } from '@/api-client';
import { CreateExaminerAllocationInput, ExamDayPlanStatusEnum, SearchExamDayPlanRequest, SearchExamDayPlanResponse, ExaminerAvailabilityResponse } from '@/api-client/client';
import { defineComponent, getCurrentInstance, onMounted, reactive, ref, toRefs, onActivated } from "vue";
import moment from "moment-timezone"
import { message } from 'ant-design-vue';
import ModifyCentralAllocation from './components/modifyCentralAllocation.vue'
import { useRouter } from 'vue-router';
type RangeValue = [Dayjs, Dayjs];

export default defineComponent({
  components: {
    ModifyCentralAllocation,
  },

  setup() {
    const { push } = useRouter();
    const dates = ref<RangeValue>([dayjs().add(1, 'day'), dayjs().add(15, 'day')]);;
    const value = ref<RangeValue>([dayjs().add(1, 'day'), dayjs().add(15, 'day')]);

    const modifyCentralAllocationDate = ref<SearchExamDayPlanResponse>();
    const hackValue = ref<RangeValue>();
    const isShowModify = ref<boolean>(false);
    const today = dayjs();

    const disabledDate = (current: Dayjs) => {
      if (!dates.value || (dates.value as any).length === 0) {
        return false;
      }
      const tooLate = dates.value[0] && current.diff(dates.value[0], 'days') > 179;
      const tooEarly = dates.value[1] && dates.value[1].diff(current, 'days') > 179;
      return tooEarly || tooLate;
    };

    const onOpenChange = (open: boolean) => {
      if (open) {
        dates.value = [] as any;
        hackValue.value = [] as any;
      } else {
        hackValue.value = undefined;
      }
    };

    const onChange = (val: RangeValue) => {
      value.value = val;
    };

    const onCalendarChange = (val: RangeValue) => {
      dates.value = val;
    };

    const momentTimeZone = moment
    const internalInstance = getCurrentInstance();
    const statusList = [
      { id: true, name: 'Enable' },
      { id: false, name: 'Disable' }
    ]
    const columns = [
      {
        title: 'Date',
        dataIndex: 'dateStr',
        key: 'dateStr',
        width: 75
      },
      {
        title: 'Week',
        dataIndex: 'weekDay',
        key: 'weekDay',
        width: 20
      },
      {
        title: 'Status',
        dataIndex: 'statusStr',
        key: 'statusStr',
        width: 100
      },
      {
        title: 'IOC-SPK-Task',
        dataIndex: 'IOCSPKTask',
        key: 'IOCSPKTask',
        width: 100
      },
      {
        title: 'IOP-SPK-Task',
        dataIndex: 'IOPSPKTask',
        key: 'IOPSPKTask',
        width: 100
      },
      {
        title: 'OSM',
        dataIndex: 'osmTaskNumber',
        key: 'osmTaskNumber',
        width: 100
      },
      {
        title: 'JP',
        dataIndex: 'jpTaskNumber',
        key: 'jpTaskNumber',
        width: 100
      },
      {
        title: 'EOR',
        dataIndex: 'eorTaskNumber',
        key: 'eorTaskNumber',
        width: 100
      },
      {
        title: 'KPI',
        dataIndex: 'score',
        key: 'score',
        width: 40
      },
      {
        title: 'Synced Status',
        key: 'Synced_Status',
        width: 110
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        width: 50
      }
    ]

    const now = new Date();
    const tableLoading = ref<boolean>(false)
    const tableSource = ref<SearchExamDayPlanResponse[]>();
    const searchParames = reactive<SearchExamDayPlanRequest>(new SearchExamDayPlanRequest({ startTime: now, endTime: now }));

    async function getList() {
      try {
        if (!dates.value) {
          message.warning("Please select Date.");
          return;
        }
        tableLoading.value = true
        let startTime = dates!.value![0].toDate();
        let endTime = dates!.value![1].toDate();
        searchParames.startTime = new Date(startTime.getFullYear() + "-" + (startTime.getMonth() + 1).toString().padStart(2, '0') + "-" + (startTime.getDate()).toString().padStart(2, '0'));
        searchParames.endTime = new Date(endTime.getFullYear() + "-" + (endTime.getMonth() + 1).toString().padStart(2, '0') + "-" + (endTime.getDate()).toString().padStart(2, '0'));

        tableSource.value = await _Client.examDayPlanClient.search(searchParames)
      }
      finally {
        tableLoading.value = false
      }
    }
    async function resetSearchParams() {
      dates.value = [dayjs().add(1, 'day'), dayjs().add(15, 'day')];
      value.value = [dayjs().add(1, 'day'), dayjs().add(15, 'day')];
      searchParames.status = undefined;
      await getList()
    };
    async function ShowModify(centralAllocationDate: SearchExamDayPlanResponse) {
      modifyCentralAllocationDate.value = centralAllocationDate;
      isShowModify.value = true;
    }

    async function CloseModify(isRefresh: Boolean) {
      isShowModify.value = false;
      if (isRefresh) {
        await getList()
      }
    }

    const toDetail = (examDayPlan: SearchExamDayPlanResponse) => {
      let goToRouter = `/centralAllocation/CentralAllocation/AllocationDetails/${examDayPlan.planId}/${examDayPlan.dateStr}`;
      push(goToRouter);
    }

    onActivated(async () => {
      await getList()
    })

    return {
      momentTimeZone,
      statusList,
      columns,
      tableLoading,
      getList,
      resetSearchParams,
      searchParames,
      dates,
      value,
      hackValue,
      disabledDate,
      onOpenChange,
      onChange,
      onCalendarChange,
      tableSource,
      ExamDayPlanStatusEnum,
      modifyCentralAllocationDate,
      isShowModify,
      ShowModify,
      CloseModify,
      now,
      toDetail,
      today,
      dayjs,
    }
  }
})


