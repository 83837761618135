/* tslint:disable */

import {
  RegionClient,
  ExaminerHubClient,
  AuthClient,
  AdminUserClient,
  MenuClient,
  RoleClient,
  ActionOperationLogClient,
  ExaminerClient,
  CityClient,
  UploadAttachmentClient,
  ProvinceClient,
  CertificationRecordClient,
  AuditLogClient,
  CoreDayManagementClient,
  AvailabilityManagementClient,
  AvailabilityLockPeriodClient,
  NoneExaminingDateClient,
  TrainingRecordsClient,
  MonitorRecordsClient,
  AllocationSettingsClient,
  ExamDayPlanClient,
  UploadOSMWorkRecordClient,
  ExaminerAllocationManualArrangementClient,
  ChangeExaminerAvailabilityClient,
  SPKWorkRecordsClient,
} from './client'
const baseUrl = process.env.VUE_APP_BASE_URL

const regionClient = new RegionClient(baseUrl)
const examinerHubClient = new ExaminerHubClient(baseUrl)
const authClient = new AuthClient(baseUrl)
const adminUserClient = new AdminUserClient(baseUrl)
const menuClient = new MenuClient(baseUrl)
const roleClient = new RoleClient(baseUrl)
const examinerClient = new ExaminerClient(baseUrl)
const actionOperationLogClient = new ActionOperationLogClient(baseUrl)
const cityClient = new CityClient(baseUrl)
const uploadAttachmentClient = new UploadAttachmentClient(baseUrl)
const provinceClient = new ProvinceClient(baseUrl)
const certificationRecordClient = new CertificationRecordClient(baseUrl);
const auditLogClient = new AuditLogClient(baseUrl);
const coreDayManagementClient = new CoreDayManagementClient(baseUrl)
const availabilityManagementClient = new AvailabilityManagementClient(baseUrl);
const availabilityLockPeriodClient = new AvailabilityLockPeriodClient(baseUrl);
const noneExaminingDateClient = new NoneExaminingDateClient(baseUrl);
const trainingRecordsClient = new TrainingRecordsClient(baseUrl);
const monitorRecordsClient = new MonitorRecordsClient(baseUrl);
const allocationSettingsClient = new AllocationSettingsClient(baseUrl);
const examDayPlanClient = new ExamDayPlanClient(baseUrl);
const uploadOSMWorkRecordClient = new UploadOSMWorkRecordClient(baseUrl);
const examinerAllocationManualArrangementClient = new ExaminerAllocationManualArrangementClient(baseUrl);
const changeExaminerAvailabilityClient = new ChangeExaminerAvailabilityClient(baseUrl);
const spkWorkRecordsClient = new SPKWorkRecordsClient(baseUrl);


export const _Client = {
  regionClient,
  examinerHubClient,
  authClient,
  adminUserClient,
  menuClient,
  roleClient,
  actionOperationLogClient,
  examinerClient,
  cityClient,
  uploadAttachmentClient,
  provinceClient,
  certificationRecordClient,
  auditLogClient,
  coreDayManagementClient,
  availabilityManagementClient,
  availabilityLockPeriodClient,
  noneExaminingDateClient,
  trainingRecordsClient,
  monitorRecordsClient,
  allocationSettingsClient,
  examDayPlanClient,
  uploadOSMWorkRecordClient,
  examinerAllocationManualArrangementClient,
  changeExaminerAvailabilityClient,
  spkWorkRecordsClient
}
