
import { ExclamationCircleFilled } from "@ant-design/icons-vue";
import { ref, onMounted, inject, watch, onActivated, defineComponent } from "vue";
import { _Client } from "@/api-client";
import { CalculationOverallKPIInput, OverallKPIDto } from "@/api-client/client";
export default defineComponent({
  components: {
    ExclamationCircleFilled,
  },
  props: {
    close: {
      type: Function,
      required: true
    }
  },
  setup(props) {

    const planId = inject('examinerAllocationPlanId', '')
    var data = ref<OverallKPIDto>(new OverallKPIDto())
    var allocationChangedList = ref<string[]>();
    var loading = ref(false)
    var allocationChangedLoading = ref(true)

    const close = () => {
      props.close(data.value.overallScore)
    }

    onMounted(() => {
      loading.value = true
      allocationChangedLoading.value = true
      _Client.examDayPlanClient.calculationOverallKPI(new CalculationOverallKPIInput({ planId: planId })).then(rep => {
        data.value = rep
      }).finally(() => {
        loading.value = false
      })

      _Client.examDayPlanClient.getAllocationChanged(planId).then(rep => {
        allocationChangedList.value = rep
      }).finally(() => {
        allocationChangedLoading.value = false
      })
    })

    return {
      close,
      data,
      loading,
      allocationChangedLoading,
      allocationChangedList
    }
  }
})
