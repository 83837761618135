

import {
    ref,
    defineComponent,
    onMounted,
    onActivated,
    createVNode,
    reactive,
} from "vue";

import { _Client } from "@/api-client";
import AddWorkRecords from './addTrainingRecords.vue'
import LogDetail from '@/components/LogDetail/index.vue'
import { ExaminerLastSessionStatusEnum, TrainingRecordsDto,GetAllRegionRep, PagedResultOfExaminerInfoDto,ExaminerInfoDto, GetTrainingRecordLogByExaminerIdRequest, SearchExaminerRequest, PagedResultOfTrainingRecordsDto, SearchTrainingRecordsByExaminerRequest,ModifyTrainingRecordsInput } from "@/api-client/client";
import { LogDetailModel, LogModel } from '@/api-client/modal';
import { List, Modal, message } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import Pagination from '@/components/Pagination/index.vue';
import modifyAvailability from "@/views/availabilityManagement/availibility/modifyAvailability";

//// TrainingRecords  主列表（考官列表）
const columns = [
    {
        title: 'Region',
        dataIndex: 'regionIdName',
    },
    {
        title: 'Examiner No.',
        dataIndex: 'examinerNo'
    },
    {
        title: 'Examiner Name',
        dataIndex: 'examinerName'
    },
    {
        title: 'PSN Last Session Status',
        dataIndex: 'speakingLastSessionStatusStr'
    },
    {
        title: 'ICS Last Session Status',
        dataIndex: 'icsLastSessionStatusStr'
    },
    {
        title: 'Action',
        dataIndex: 'action',
        fixed: 'right',
        width: 200
    }
]

//// TrainingRecords  主列表（考官列表）
const TrainingRecordsColumns = [
    {
        title: 'Training Date',
        dataIndex: 'trainingDate',
    },
    {
        title: 'Training Type',
        dataIndex: 'trainingType'
    },
    {
        title: 'Training Category',
        dataIndex: 'trainingCategory'
    },
    {
        title: 'Training Session',
        dataIndex: 'trainingSession'
    },
    {
        title: 'Trainer',
        dataIndex: 'trainer'
    },
    {
        title: 'Training Status',
        dataIndex: 'trainingStatus'
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
    }
]
export default defineComponent({
    components: {

        Pagination,
        LogDetail,
        AddWorkRecords
    },
    setup() {
        const selectedRowKeys = ref<string[]>([]); //// checkbox 已选中的数据
        const showAddModal = ref(false);

        const showModifyModal=ref(false);
        const modifyLoading=ref(false);
        const modifyModel=ref<ModifyTrainingRecordsInput>(new ModifyTrainingRecordsInput());
        const modifyTrainingStatus=ref("Completed");

        const tableLoading = ref<boolean>(false);
        const trainingRecordsTableLoading = ref<boolean>(false);
        const tableSource = ref(new PagedResultOfExaminerInfoDto());
        const trainingRecordsTableSource = ref(new PagedResultOfTrainingRecordsDto());
        const titleTrainingRecords = ref<String>("");
        const showTrainingRecordsModal = ref<boolean>(false);
        const searchParames = ref<SearchExaminerRequest>(new SearchExaminerRequest({ pageIndex: 1, pageSize: 10 }));
        const searchTrainingRecordsParames = ref<SearchTrainingRecordsByExaminerRequest>(new SearchTrainingRecordsByExaminerRequest({ examinerId: "", pageIndex: 1, pageSize: 10 }));
        const logDetailRef = ref(null)
        const region = reactive<{
            regionList: GetAllRegionRep[],
        }>({
            regionList: new Array<GetAllRegionRep>(),
        });

        const logState = reactive<{
            logSearchParames: GetTrainingRecordLogByExaminerIdRequest,
            logDetail: LogDetailModel,
            showLogDialog: Function,
            getLogList: Function
        }>({
            logSearchParames: new GetTrainingRecordLogByExaminerIdRequest(<GetTrainingRecordLogByExaminerIdRequest>{ pageIndex: 1 }),
            logDetail: new LogDetailModel(),

            showLogDialog: async (examinerId: string) => {
                logState.logSearchParames.pageIndex = 1;
                logState.logSearchParames.examinerId = examinerId
                await logState.getLogList()
            },

            getLogList: async () => {
                try {
                    let logDetail = JSON.parse(JSON.stringify(await _Client.trainingRecordsClient.searchTrainingRecordLogByExaminerId(logState.logSearchParames)))
                    if (!logDetail) {
                        return;
                    }
                    logDetail.list = logDetail.list?.map((logItem: { createdOn: Date; operation: string; createdBy: string; }) => {
                        return new LogModel({
                            createdTime: logItem.createdOn,
                            operation: logItem.operation,
                            userName: logItem.createdBy
                        })
                    })
                    logState.logDetail = new LogDetailModel(logDetail)
                }
                finally {
                    (logDetailRef.value as any).showLogModal = true
                }
            }
        })


        async function getRegionList() {
            region.regionList = await _Client.regionClient.getAll(true)
        }

        const search = (() => {
            searchParames.value.pageIndex = 1;
            getList();
        })

        const disabledModify = ((record:TrainingRecordsDto) => {

           if(record.trainingSession!="ICS Training" && record.trainingSession!="PSN Self-Access Training" && record.trainingSession!="ICS Self-Access Training")
           {
                return true;
           }

           if(record.trainingStatus=="Completed")
           {
                return true;
           }

           return false;
        })

        const getList = (() => {
            tableLoading.value = true;
            _Client.trainingRecordsClient.search(searchParames.value!).then(rep => {
                tableSource.value = rep;
            }).finally(() => {tableLoading.value = false; selectedRowKeys.value = []; })
        })

        const resetSearchParams = () => {
            searchParames.value = new SearchExaminerRequest(<SearchExaminerRequest>{ pageIndex: 1 })

            getList();
        }

        const gettrainingRecords = (() => {
            trainingRecordsTableLoading.value = true;
            _Client.trainingRecordsClient.searchTrainingRecordByExaminerId(searchTrainingRecordsParames.value!).then(rep => {
                trainingRecordsTableSource.value = rep;
            }).finally(() => trainingRecordsTableLoading.value = false)

        })


        const showtrainingRecords = ((examinerNo: string, examinerId: string, examinerName: string) => {
            searchTrainingRecordsParames.value.pageIndex = 1;
            showTrainingRecordsModal.value = true;
            titleTrainingRecords.value = "View Detail：" + examinerName + " " + examinerNo;
            searchTrainingRecordsParames.value.examinerId = examinerId;
            gettrainingRecords();
        })

        const closetrainingRecords = (() => {
            showTrainingRecordsModal.value = false;
            titleTrainingRecords.value = "";
            searchTrainingRecordsParames.value.examinerId = "";
        })

        //// 复选框筛选
        const onSelectChange = (
          keys: string[],
          selectedRows: ExaminerInfoDto[]
        ) => {
          selectedRowKeys.value = keys;
        };

          // 关闭AddWorkRecord弹窗
        const closeAddWorkRecord = (isRefresh: boolean) => {
          selectedRowKeys.value = [];
          showAddModal.value = false;
          if (isRefresh) {
            getList();
          }
        }

        const AddTrainingRecord = () => {
           showAddModal.value = true;
        }

        const shwoModifyTrainingRecords=(record: TrainingRecordsDto)=>{
            showModifyModal.value=true;

            modifyModel.value.trainingRecordId=record.trainingRecordId;
            modifyModel.value.traIningStatus=record.trainingStatus;
        }

        // 修改
        const modifTrainingRecords = () => {
            modifyLoading.value = true;
            modifyModel.value.traIningStatus=modifyTrainingStatus.value;
            _Client.trainingRecordsClient.modifyTrainingRecords(modifyModel.value!).then((res) => {
                if(res.isSuccessful){
                    message.success("Save Successfully");
                    gettrainingRecords();
                }
                else{
                    message.error(res.message);
                }
            }).finally(() => {
                modifyLoading.value = false;
                showModifyModal.value=false;
            })
        }

        // 删除
        const deleteTrainingRecords = (id: string) => {
          Modal.confirm({
                    title: 'Delete',
                    content: "Do you want to delete this training records?",
                    icon: createVNode(ExclamationCircleOutlined),
                    okText: "Confirm",
                    cancelText: "Cancel",
                    onOk: () => {
                        trainingRecordsTableLoading.value = true;
                       _Client.trainingRecordsClient.deleteTrainingRecords(id).then(rep => {
                         message.success("Save Successfully");
                         gettrainingRecords();
                       }).catch(() => {
                         message.error("Save Failed")
                        }).finally(() => { trainingRecordsTableLoading.value = false; })
                    },
                });
        }
        
        onMounted(() => {
            getRegionList();
            getList();
        });

        onActivated(() => {

        });


        return {
            tableLoading,
            trainingRecordsTableLoading,
            showTrainingRecordsModal,
            region,
            columns,
            TrainingRecordsColumns,
            tableSource,
            trainingRecordsTableSource,
            searchParames,
            searchTrainingRecordsParames,
            titleTrainingRecords,
            logState,
            logDetailRef,
            selectedRowKeys,
            showAddModal,
            showModifyModal,
            modifyTrainingStatus,
            modifyLoading,
            examinerLastSessionStatusEnum:ExaminerLastSessionStatusEnum,
            shwoModifyTrainingRecords,
            modifTrainingRecords,
            deleteTrainingRecords,
            onSelectChange,
            close,
            search,
            getList,
            resetSearchParams,
            gettrainingRecords,
            showtrainingRecords,
            closetrainingRecords,
            closeAddWorkRecord,
            AddTrainingRecord,
            disabledModify
        };
    }
});

