import { _Client } from '@/api-client';
import { GetAllRegionRep, PagedResultOfSearchCoreDayRep, SearchExaminerHubLogQuery, SearchCoreDayRequest, SearchExaminerHubRep, WorkStatusEnum, ReleaseToIEPInput, GetReleaseToIEPStatusInput, CheckExaminerInput } from '@/api-client/client';
import { defineComponent, getCurrentInstance, h, onActivated, onMounted, onUnmounted, reactive, ref, toRefs } from "vue";
import AddExaminerCoreDay from './components/AddExaminerCoreDay.vue'
import ModifyExaminerCoreDay from './components/modifyExaminerCoreDay.vue'
import Pagination from '@/components/Pagination/index.vue'
import LogDetail from '@/components/LogDetail/index.vue'
import moment from "moment-timezone"
import { checkAccess } from '@/utils/common';
import { Form, Switch, message, notification } from 'ant-design-vue';
import { LogDetailModel, LogModel } from '@/api-client/modal';
import { QuestionCircleOutlined, ExclamationCircleOutlined, ExclamationCircleFilled, CloseOutlined } from '@ant-design/icons-vue';
import dayjs from 'dayjs';

export default defineComponent({
  components: {
    AddExaminerCoreDay,
    ModifyExaminerCoreDay,
    Pagination,
    LogDetail,
    QuestionCircleOutlined,
    ExclamationCircleOutlined,
    ExclamationCircleFilled,
    CloseOutlined
  },
  setup() {
    const examinerList = ref<any>([]);
    const selectedRowKeys = ref<any>([]); //// checkbox 已选中的数据
    const releaseToIEP = ref(false);
    const momentTimeZone = moment
    const internalInstance = getCurrentInstance();
    const workStatusEnum = WorkStatusEnum;

    const statusList = [
      { id: true, name: 'Enable' },
      { id: false, name: 'Disable' }
    ]
    const columns = [
      {
        title: 'Examiner No.',
        dataIndex: 'examinerNo',
        key: 'examinerNo',
        width: 120
      },
      {
        title: 'Examiner Name',
        dataIndex: 'examinerName',
        key: 'examinerName',
        width: 150
      },
      {
        title: 'Work Type',
        dataIndex: 'workStatusStr',
        key: 'workStatusStr',
        width: 110
      },
      {
        title: 'Core Day No.',
        dataIndex: 'coreDayNo',
        key: 'coreDayNo',
        width: 100
      },
      {
        title: 'Non-Core Day No.',
        key: 'nonCoreDay',
        dataIndex: 'nonCoreDay',
        width: 100
      },
      {
        title: 'IEP Core day No.',
        key: 'iepCoreDay',
        dataIndex: 'iepCoreDay',
        width: 100
      }
    ]

    const releaseDate = ref<Date>();
    const releaseToIEPInput = reactive(new ReleaseToIEPInput());
    const showEditModal = ref<boolean>(false)
    const showConfirm = ref<boolean>(false)
    const showAddCoreDayModal = ref<boolean>(false)
    const tableLoading = ref<boolean>(false)
    const confirmLoading = ref(false)
    const modifyExaminerId = ref<string>()
    const modifyExaminerNo = ref<string>()
    const modifyExaminerName = ref<string>()
    const listState = reactive<{
      regionList: GetAllRegionRep[],
      searchParames: SearchCoreDayRequest,
      tableSource: PagedResultOfSearchCoreDayRep
    }>({
      regionList: Array<GetAllRegionRep>(),
      searchParames: new SearchCoreDayRequest({
        pageIndex: 1,
        pageSize: 10,
      }),
      tableSource: new PagedResultOfSearchCoreDayRep(),
    })
    const onSelectChange = (keys: any) => {
      selectedRowKeys.value = keys;
    };
    async function getRegionList() {
      listState.regionList = await _Client.regionClient.getAll(true)
    }
    async function getList() {
      selectedRowKeys.value = []
      try {
        tableLoading.value = true
        listState.tableSource = await _Client.coreDayManagementClient.search(listState.searchParames)
      }
      finally {
        tableLoading.value = false
      }
    }
    const changeSearchParames = async () => {
      listState.searchParames.pageIndex = 1
      await getList()
    };
    async function resetSearchParams() {
      selectedRowKeys.value = []
      listState.searchParames = new SearchCoreDayRequest()
      await getList()
    };
    const AddOrModifyOperateion = () => {
      const AddExaminerCoreDayRef = ref(null)
      const modifyExaminerHubRef = ref(null)


      //show
      async function showAddCoreDay() {
        examinerList.value = [];
        selectedRowKeys.value.forEach((rowkey: string) => {
          let itemExaminer = listState.tableSource.list!.find(item => item.examinerId === rowkey);
          examinerList.value.push(
            {
              examinerId: rowkey,
              examinerName: itemExaminer?.examinerName,
              examinerNo: itemExaminer?.examinerNo
            })
        });
        showAddCoreDayModal.value = true
      };
      async function showModifyModal(record: any) {
        modifyExaminerId.value = record.examinerId;
        modifyExaminerNo.value = record.examinerNo;
        modifyExaminerName.value = record.examinerName;
        showEditModal.value = true
      }
      //close
      async function closeAddCoreDay(refresh: boolean = false) {
        showAddCoreDayModal.value = false
        if (refresh) {
          await getList()
        }
      }
      async function closeModifyModal(refresh: boolean = false) {
        showEditModal.value = false
        if (refresh) {
          await getList()
        }
      }
      return { showAddCoreDay, AddExaminerCoreDayRef, modifyExaminerHubRef, showEditModal, ...toRefs(modifyExaminerId), showAddCoreDayModal, showModifyModal, modifyExaminerNo, closeAddCoreDay, closeModifyModal }
    }

    const coreDayReleaseToIEP = () => {
      if (releaseDate.value == undefined || releaseDate.value == null) {
        message.warning("Please select date");
        return false;
      }
      showConfirm.value = true
    }

    const coreDayReleaseToIEPConfirm = async () => {
      confirmLoading.value = true;
      releaseToIEPInput.releaseDate = new Date(dayjs(releaseDate.value, 'DD/MM/YYYY').format('YYYY-MM-DD'))

      let result = await _Client.coreDayManagementClient.coreDayReleaseToIEP(releaseToIEPInput)
      if (result) {
        showConfirm.value = false;
        confirmLoading.value = false;
        message.success("success");
        await getList()
      }
      else {
        message.warning("Core day data is being synchronized, please try again later.");
        confirmLoading.value = false;
      }
    }

    const logOperation = () => {
      const logDetailRef = ref(null)
      const logState = reactive<{ logSearchParames: SearchExaminerHubLogQuery, logDetail: LogDetailModel }>({
        logSearchParames: new SearchExaminerHubLogQuery(),
        logDetail: new LogDetailModel()
      })

      async function showLogDialog(examinerHub: SearchExaminerHubRep) {
        let hasEditPermission = await checkAccess(`${internalInstance!.appContext.config.globalProperties.$pageName}:${internalInstance!.appContext.config.globalProperties.$actionNames.ViewLog}`, examinerHub!.officeId)
        if (!hasEditPermission) { return }

        logState.logSearchParames = { ...logState.logSearchParames, pageIndex: 1, examinerHubId: examinerHub.id }
        await getLogList()
      }
      async function getLogList() {
        try {
          let logDetail = JSON.parse(JSON.stringify(await _Client.examinerHubClient.getExaminerHubLog(logState.logSearchParames)))
          logDetail.list = logDetail.list?.map((logItem: { createdOn: Date; operation: string; createdBy: string; }) => {
            return new LogModel({
              createdTime: logItem.createdOn,
              operation: logItem.operation,
              userName: logItem.createdBy
            })
          })
          logState.logDetail = new LogDetailModel(logDetail)
        }
        finally {
          (logDetailRef.value as any).showLogModal = true
        }
      }

      return { logDetailRef, ...toRefs(logState), showLogDialog, getLogList }
    }

    const getReleaseToIEPStatus = () => {
      _Client.coreDayManagementClient.getReleaseToIEPStatus(new GetReleaseToIEPStatusInput({ releaseDate: new Date(dayjs(releaseDate.value, 'DD/MM/YYYY').format('YYYY-MM-DD')) })).then(rep => {
        releaseToIEP.value = rep;
      })
    }

    const checkLoading = ref(false)

    const checkExaminer = () => {
      if (!listState.searchParames.examinerNoStr?.length) {
        return;
      }

      checkLoading.value = true;
      _Client.coreDayManagementClient.checkExaminer(new CheckExaminerInput({ examinerNos: listState.searchParames.examinerNoStr!.split(',') }))
        .then(rep => {
          let msg = ''
          if (rep.isSuccess) {
            msg = "All entered examiners can be found."
          }
          else {
            msg = `Not Found Examiners: ${rep.examinerNos!.join(",")}`
          }

          showCheckResult(rep.isSuccess, msg)

        }).finally(() => checkLoading.value = false)
    }

    const showCheckResult = (isOk: boolean, msg: string) => {
      let title = isOk ? 'Notes' : 'Warning'
      let iconColor = isOk ? '#1890FF' : '#F7941D'
      
      notification.open({
        icon: () => h(ExclamationCircleFilled, { style: `color: ${iconColor};font-size:17px` }),
        message: title,
        description: msg,
        getContainer: ()=> document.getElementById('notification-table-header')!,
        style: {
          color: '#666666',
          padding: "8px 15px",
          wordBreak: 'break-all'
        },
        top:'320px',
        class: `c_coreday_check_notify_${isOk ? 'ok' : 'err'}`,
      });
    }

    onMounted(async () => {
      await getList()
      await getRegionList()
    })

    onActivated(() => {
      notification.destroy();
  })

    return {
      momentTimeZone,
      statusList,
      columns,
      tableLoading,
      ...toRefs(listState),
      getList,
      coreDayReleaseToIEP,
      coreDayReleaseToIEPConfirm,
      changeSearchParames,
      resetSearchParams,
      ...AddOrModifyOperateion(),
      ...logOperation(),
      modifyExaminerId,
      modifyExaminerName,
      modifyExaminerNo,
      selectedRowKeys,
      showConfirm,
      releaseToIEP,
      onSelectChange,
      examinerList,
      workStatusEnum,
      confirmLoading,
      releaseDate,
      getReleaseToIEPStatus,
      checkExaminer,
      checkLoading
    }
  }
})