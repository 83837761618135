
import { _Client } from '@/api-client';
import { SearchSPKWorkRecordInput, GlobalExaminerDto, PageResultOfListOfSPKWorkRecordsDto } from '@/api-client/client';
import { defineComponent, onMounted, reactive, ref,createVNode } from "vue";
import Pagination from '@/components/Pagination/index.vue';
import AddWorkRecords from './addWorkRecords.vue';
import Log from './Log.vue';
import dayjs, { Dayjs } from "dayjs";
import {  Modal,message } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";

type RangeValue = [Dayjs, Dayjs];

export default defineComponent({
  components: {
    Pagination,
    AddWorkRecords,
    Log
  },

  setup() {
    const columns = [
      {
        title: 'Examiner No.',
        dataIndex: 'examinerNo',
        key: 'examinerNo',
        width: 100
      },
      {
        title: 'Examiner Name',
        dataIndex: 'examinerName',
        key: 'examinerName',
        width: 120
      },
      {
        title: 'ICS Test Date',
        dataIndex: 'icsTestDate',
        key: 'icsTestDate',
        width: 50
      },
      {
        title: 'F2F Test Date',
        dataIndex: 'f2FTestDate',
        key: 'f2FTestDate',
        width: 50
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        width: 100
      }
    ]
    
    const typeStr = ref("");
    
    const showAddModal = ref(false);
    const showLogModal = ref(false);
    const logExaminerId=ref("");
    const showDelModal = ref(false);
    const tableLoading = ref<boolean>(false);
    const dataSource = reactive<PageResultOfListOfSPKWorkRecordsDto>(new PageResultOfListOfSPKWorkRecordsDto());
    const cardLoading = ref<boolean>(false);
    const selectedRowKeys = ref<string[]>([]); //// checkbox 已选中的数据
    const searchParames = reactive<SearchSPKWorkRecordInput>(new SearchSPKWorkRecordInput({
      pageIndex: 1,
      pageSize: 10,
      examinerNo: undefined,
      firstName: undefined,
      lastName: undefined
    }));

    const icshackValue = ref<RangeValue>();
    const icsdates = ref<RangeValue>();
    const icsvalue = ref<RangeValue>();
    const icsonOpenChange = (open: boolean) => {
      if (open) {
        icsdates.value = [] as any;
        icshackValue.value = [] as any;
      } else {
        icshackValue.value = undefined;
      }
    };

    const icsonCalendarChange = (val: RangeValue) => {
      icsdates.value = val;
    };

    const icsonChange = (val: RangeValue) => {
      icsvalue.value = val;
    };

    const f2fhackValue = ref<RangeValue>();
    const f2fdates = ref<RangeValue>();
    const f2fvalue = ref<RangeValue>();
    const f2fonOpenChange = (open: boolean) => {
      if (open) {
        f2fdates.value = [] as any;
        f2fhackValue.value = [] as any;
      } else {
        f2fhackValue.value = undefined;
      }
    };

    const f2fonCalendarChange = (val: RangeValue) => {
      f2fdates.value = val;
    };

    const f2fonChange = (val: RangeValue) => {
      f2fvalue.value = val;
    };

    const search = () => {
      searchParames.pageIndex = 1;
      getList();
    }

    const resetSearchParams = () => {
      searchParames.init(new SearchSPKWorkRecordInput({
        pageIndex: 1,
        pageSize: 10,
        examinerNo: undefined,
        firstName: undefined,
        lastName: undefined,
        icsTestTypeStart:undefined,
        icsTestTypeEnd:undefined,
        f2FTestTypeStart:undefined,
        f2FTestTypeEnd:undefined
      }));

      icsvalue.value = undefined;
      f2fvalue.value = undefined;
      f2fdates.value=undefined;
      icsdates.value=undefined;

      getList();
    }


    //// 复选框筛选
    const onSelectChange = (
      keys: string[],
      selectedRows: GlobalExaminerDto[]
    ) => {
      selectedRowKeys.value = keys;
    };

    const getList = () => {
      tableLoading.value = true;
      searchParames.icsTestTypeStart=undefined;
      searchParames.icsTestTypeEnd=undefined;
      searchParames.f2FTestTypeStart=undefined;
      searchParames.f2FTestTypeEnd=undefined;
      if(icsdates.value!=undefined){
      searchParames.icsTestTypeStart=new Date(icsdates!.value![0].format("YYYY-MM-DD"))
      searchParames.icsTestTypeEnd=new Date(icsdates!.value![1].format("YYYY-MM-DD"))
      }
      if(f2fdates.value!=undefined){
      searchParames.f2FTestTypeStart=new Date(f2fdates!.value![0].format("YYYY-MM-DD"))
      searchParames.f2FTestTypeEnd=new Date(f2fdates!.value![1].format("YYYY-MM-DD"))
      }
      _Client.spkWorkRecordsClient.search(searchParames).then(rep => {
        dataSource.cnt = rep.cnt;
        dataSource.ret = rep.ret;
      }).finally(() => { tableLoading.value = false; selectedRowKeys.value = []; })
    }

    // 删除
    const deleteWorkRecords = (typeStr:string,activityTestId: string) => {
      Modal.confirm({
                title: 'Delete',
                content: "Do you want to delete this "+typeStr+" work record? ",
                icon: createVNode(ExclamationCircleOutlined),
                okText: "Delete",
                cancelText: "Cancel",
                onOk: () => {
                    tableLoading.value = true;
                   _Client.spkWorkRecordsClient.deleteWorkRecords(activityTestId).then(rep => {
                     message.success("Save Successfully");
                     showDelModal.value=false;
                     getList();
                   }).catch(() => {
                     message.error("Save Failed")
                    }).finally(() => { tableLoading.value = false; selectedRowKeys.value = []; })
                },
            });
    }

    // 批量添加WorkRecord
    const batchAddWorkRecord = () => {
      showAddModal.value = true;
    }

    // log
    const showLog = (examinerId:string) => {
      logExaminerId.value = examinerId;
      showLogModal.value = true;
    }

     // 关闭AddWorkRecord弹窗
     const closeAddWorkRecord = (isRefresh: boolean) => {
      selectedRowKeys.value = [];
      showAddModal.value = false;
      if (isRefresh) {
        getList();
      }
    }

     // 关闭log弹窗
     const closeLog = () => {
      logExaminerId.value = "";
      showLogModal.value = false;
    }
    
    onMounted(() => {
      // getList();
    })

    return {
      cardLoading,
      columns,
      tableLoading,
      searchParames,
      selectedRowKeys,
      dataSource,
      showAddModal,
      typeStr,
      showDelModal,
      logExaminerId,
      showLogModal,
      showLog,
      batchAddWorkRecord,
      closeLog,
      closeAddWorkRecord,
      deleteWorkRecords,
      resetSearchParams,
      onSelectChange,
      getList,
      search,
      dayjs,
      icsvalue,
      icshackValue,
      icsonChange,
      icsonCalendarChange,
      icsonOpenChange,
      f2fhackValue,
      f2fvalue,
      f2fonChange,
      f2fonCalendarChange,
      f2fonOpenChange,
    }
  }
})
