
import { PropType, reactive, ref, defineComponent, onMounted, } from "vue";
import { CalendarOutlined, SmileOutlined } from "@ant-design/icons-vue";
import { ExaminerActivityTestTypeEnum, SPKWorkRecordsExaminerDto, AddSpkWorkRecordInput } from "@/api-client/client";
import { _Client } from "@/api-client";
import { Form, message } from "ant-design-vue";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import { FormInstance } from 'ant-design-vue/lib/form';

const useForm = Form.useForm;

export default defineComponent({
    props: {
        examinerIdList: {
            type: Array as PropType<string[]>,
            required: true,
        },
        onClose: {
            type: Function,
            required: true,
        },
    },
    components: {
        CalendarOutlined,
        SmileOutlined,
    },
    setup(props) {
        const formRef = ref(null);
        // 表单模型
        const form = ref({
          testDate: null,
          type: null,
        });

        // 校验规则
        const rules = {
          testDate: [
            { required: true, message: 'Test Date is required', trigger: 'change' },
          ],
          type: [
            { required: true, message: 'SPK Test Type is required', trigger: 'change' },
          ],
        };
       
        const examinerIdList = props.examinerIdList;
        const saveLoading = ref(false);
        const spinning = ref(false);
        const title = ref<string>("Edit Examiner Task");
        const examinerForm = ref<SPKWorkRecordsExaminerDto[]>([]);
        const close = (isRefresh: Boolean) => props.onClose(isRefresh);

         // 禁用未来日期的方法
         const disabledFutureDate = (current: Dayjs) => {
            return current && current > dayjs().endOf('day'); 
        };

        // 处理关闭标签的事件
        const handleClose = (
            tagToRemove: { examinerId?: string | undefined; examinerName?: string | undefined },
            index: number
        ) => {
            // 从数组中移除标签
            examinerForm.value?.splice(index, 1);
        };

        const getExaminerById = () => {
            spinning.value = true;
            _Client.spkWorkRecordsClient.getExaminerById(examinerIdList).then(rep => {
                examinerForm.value=rep;
            }).finally(() => { spinning.value = false; })
        }

        const addWorkRecords = () => {
            formRef.value?.validate().then(() => {
            spinning.value = true;
            saveLoading.value = true;

            _Client.spkWorkRecordsClient.addWorkRecords(new AddSpkWorkRecordInput({
                examinerIdList: examinerForm.value.map(x => x.examinerId),
                testDate:new Date(form.value.testDate!.format("YYYY-MM-DD")),
                spkTestType:form.value.type!
            })).then((res) => {
                if(res.isSuccessful){
                    message.success("Save Successfully");
                    close(true);
                }
                else{
                    message.error(res.message);
                }
            }).finally(() => {
                spinning.value = false;
                saveLoading.value = false;
            })
        })
        }

        onMounted(() => {
            getExaminerById();
        })

        return {
            close,
            handleClose,
            addWorkRecords,
            disabledFutureDate,
            formRef,
            form,
            rules,
            title,
            dayjs,
            saveLoading,
            spinning,
            examinerForm,
            examinerActivityTestTypeEnum:ExaminerActivityTestTypeEnum
        };
    },
});
